import XLSX from "sheetjs-style";
import { customAlphabet } from "nanoid";

export const getTextFieldFormikProps = (formik, key) => {
  const fieldProps = formik?.getFieldProps(key);
  // console.log("FP",fieldProps)
  return {
    id: key,
    name: key,
    value: fieldProps?.value == null ? "" : fieldProps?.value,
    helpertext: formik.touched[key] && formik.errors[key],
    ...fieldProps,
  };
};

export const exportToExcel = (excelData, fileName) => {
  /* pass here the json data */
  const dataObj = excelData[0];
  const keys = Object.keys(dataObj).sort()

  const ws = XLSX.utils.json_to_sheet(excelData, { header: keys });
  /* generate workbook and add the worksheet */
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  /* save to file */
  XLSX.writeFile(wb, fileName + ".xlsx");
};

export const generatePassword = (length) => {
  const characters =
    "1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!@#$%^&*()";
  const pwdCharacters = customAlphabet(characters, length);
  const pwd = pwdCharacters();
  return pwd;
};

export const generateRand = (length) => {
  const characters =
    "1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  const pwdCharacters = customAlphabet(characters, length);
  const rand = pwdCharacters();
  return rand;
};

export const debounce = (func, wait) => {
  let debounceTimer;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), wait);
  };
};

export const FormatNumber = (number) => {
  return number.toLocaleString(undefined, {
    maximumFractionDigits: 2,
  });
};

export const FormatCurrency = (amount) => {
  const formattedNumber = amount?.toLocaleString(undefined, {
    maximumFractionDigits: 2,
  });
  return `NGN ${formattedNumber}.00`;
};

export const FormatDateTime = (dateString) => {
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  return new Date(dateString)?.toLocaleDateString(undefined, options);
};

