import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Avatar, Button, Card } from "@mui/material";
import Spinner from "../../components/Spinner";
import { invoiceApi } from "../../apis/services/InvoiceApi";
import { FormatCurrency, FormatDateTime } from "utils/Utils";
import { orderApi } from "apis/services/OrderApi";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import CreateSchedule from "./CreateSchedule";

const tableHead = ["Name", "Quantity", "Amount"];

const ViewInvoice = () => {
  const location = useLocation();
  const { invoiceId } = location.state || {};

  const [openModal, setOpenModal] = useState(false);
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  console.log(invoiceId);
  const { data, isLoading } = invoiceApi.useGetInvoiceQuery({
    queryKeyValue: invoiceId
  });

  return (
    <>
      <Card className="flex flex-col items-center p-4 w-full space-y-4">
        <Avatar
          src={data?.data?.stationHq?.logo?.url}
          className="h-52 w-52 ring-2 ring-gray-300"
          sx={{ width: 120, height: 120 }}
        />
        <strong className="text-xl">{data?.data?.stationHq?.name}</strong>
        <div className="text-left w-full">
          <p>{`Invoice ID: ${data?.data?.invoiceNumber || ""}`}</p>
          <p>{`Order Code: ${data?.data?.order?.orderCode || ""}`}</p>
          <p>{`Due Date: ${FormatDateTime(data?.data?.dueDate) || ""}`}</p>
          <p>{`Note: ${data?.data?.invoiceNote || ""}`}</p>
        </div>
        <span
          className={
            data?.data?.paymentStatus === "credit"
              ? "px-4 py-1 rounded-full text-green-500 bg-green-500/30"
              : [
                  data?.data?.paymentStatus === "debit"
                    ? "px-4 py-1 rounded-full text-orange-500 bg-orange-500/30"
                    : "px-4 py-1 rounded-full text-red-500 bg-red-500/30"
                ]
          }
        >
          {data?.data?.paymentStatus}
        </span>

        <table className="w-full mt-10">
          <tr className="bg-blue-900/50 p-4">
            {tableHead?.map((item, index) => {
              return (
                <th className="p-4 border border-collapse border-black">
                  {item}
                </th>
              );
            })}
          </tr>
          {data?.data?.order?.details?.map((item, index) => {
            return (
              <tr>
                <td className="p-4 border border-collapse border-black">
                  {item?.product?.name}
                </td>
                {/* <td className="p-4 border border-collapse border-black">
                  {item?.requestedVolume}
                </td> */}
                <td className="p-4 border border-collapse border-black">
                  {item?.availableVolume}
                </td>
                <td className="p-4 border border-collapse border-black">
                  {FormatCurrency(item?.amount)}
                </td>
              </tr>
            );
          })}
        </table>
        <strong className="text-lg text-right w-full pr-4">{`Total Amount: ${FormatCurrency(
          data?.data?.totalAmount
        )}`}</strong>
        <Button variant="contained" onClick={() => setOpenModal(true)}>
          Schedule Pickup
        </Button>
      </Card>

      <CreateSchedule
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        invoiceId={invoiceId}
      />

      {/* Occationally used components */}
      {isLoading ? <Spinner /> : null}
    </>
  );
};

export default ViewInvoice;
