import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuthUser from "../../hooks/useAuthUser";
import { toast } from "react-toastify";

export const RequireAuth = () => {
  const { user } = useAuthUser();
  const location = useLocation();

  return user ? (
    <Outlet />
  ) : (
    <>
      {toast("Only authenticated user can navigate")}
      <Navigate to="/" state={{ path: location.pathname }} />
    </>
  );
};

// Define your guard function

//  function isAuthenticated(route, params) {
//   // Check if user is authenticated
//   const isAuthenticated = /* your authentication logic here */;

//   if (!isAuthenticated) {
//     // Redirect to login page or unauthorized page
//     window.location.href = '/login';
//     return false;
//   }

//   return true;
// }
