

export const COLORS = {
  primary: "#002E66",
  primaryLight: "#002E6690",
  background:"#f2f2f2",
  blue: "#013356",
  red: "#D80000",
  white: "#ffffff",
  black: "#000000",
  gray: "#D0D0D0",
  lightgray: "#f2f2f2",
  lightgray2: "#F2F2F2",
  darkGray: "#768089",
  orange: "#F18142",
  secondary: "#38160F90",
  darkOverlayColor: "rgba(0, 0, 0, 0.4)",
  darkOverlayColor2: "rgba(0, 0, 0, 0.8)",
  lightOverlayColor: "rgba(255, 255, 255, 0.10)",
  primaryAlpha: "rgba(99, 122, 255, 0.10)",
  redAlpha: "rgba(255, 84, 84, 0.30)",
  greenAlpha: "rgba(96, 197, 168, 0.30)",
  greenAlphaLight: "rgba(240, 253, 244, 1)",
  purpleAlpha: "rgba(146, 6, 228, 0.30)",
};

export const primaryColor = "#002E66";
export const blueColor = "#013356";
export const blueColor2 = "#0C466F";
export const redColor = "#D80000";
export const grayColor = "##C4C4C4";
export const lightgrayColor = "#f2f2f2";
export const darkgrayColor = "#768089";
export const orangeColor = "#F18142";
export const secondaryColor = "#38160F90";
