import * as Yup from "yup";

export const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("invalid email is required!")
    .required("Email is required!"),
  password: Yup.string()
    .min(6, "The password is too short")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_\-+=~])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    )
    .required("Password is required!"),
});

export const ForgotPasswordSchema = Yup.object().shape({
  token: Yup.string()
    .email("invalid token is required!")
    .required("Token is required!"),
  password: Yup.string()
    .min(6, "The password is too short")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_\-+=~])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    )
    .required("Password is required!"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required!"),
});

export const ChangePasswordSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .min(8, "The password is too short")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_\-+=~])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    )
    .required("Old Password is required!"),
  password: Yup.string()
    .min(8, "The password is too short")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_\-+=~])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    )
    .required("Password is required!"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required!"),
});

export const AddProductSchema = Yup.object().shape({
  name: Yup.string().required("Product name is required!"),
  price: Yup.number().positive().integer().required("Unit Price is required!"),
  quantity: Yup.number()
    .positive()
    .integer()
    .required("Quantity is required!"),
  unitOfMeasurement: Yup.string().required("Unit of measurement is required!"),
});

export const WithdrawalSchema = Yup.object().shape({
  amount: Yup.number().positive().integer().required("Amount is required!"),
  purpose: Yup.string().required("Narration is required!"),
  bankId: Yup.string().required("Bank UUID key is required!"),
  accountNumber:  Yup.number().positive().integer().required("Account Number is required!"),
  accountName: Yup.string().required("Account name is required!"),
  idempotentKey: Yup.string().required("Idempotent key is required!")
});
