import { AddAPhoto } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

export function DropzoneUploader({ selectedFile, currentLogo }) {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": []
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
      selectedFile(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
    }
  });

  //   console.log(files);
  const thumbs = files.map((file) => (
    <div className="w-fit bg-green-200 h-full rounded-full" key={file.name}>
      <img
        src={file.preview}
        className="flex items-center justify-center border-2 rounded-full w-[200px] h-[200px]"
        // Revoke data uri after image is loaded
        onLoad={() => {
          URL.revokeObjectURL(file.preview);
        }}
        alt=""
      />
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <section className="w-fit">
      <div
        {...getRootProps({
          className:
            "flex items-center justify-center border-2 rounded-full cursor-pointer w-[200px] h-[200px]"
        })}
      >
        <input {...getInputProps()} />
        {files.length > 0 ? (
          <aside>{thumbs}</aside>
        ) : (
          <p className="flex flex-col justify-center items-center p-4">
            {currentLogo ? (
              <img src={currentLogo} alt="currentLogo" className="rounded-full"/>
            ) : (
              <>
                <AddAPhoto />
                <span className="text-center">
                  Drag 'n' drop some files here, or click to select files.
                </span>
              </>
            )}
          </p>
        )}
      </div>
    </section>
  );
}
