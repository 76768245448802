import { CalendarToday, Edit, OilBarrel, Sell } from "@mui/icons-material";
import { Button, Card, Switch } from "@mui/material";
import React, { useState } from "react";
import { COLORS } from "../../utils/Colors";
import CustomTable from "../../components/CustomTable";
import EditProduct from "./EditProduct";
import { useLocation, useNavigate } from "react-router-dom";
import { productApi } from "../../apis/services/ProductApi";
import Spinner from "../../components/Spinner";
import { FormatDateTime, FormatNumber } from "../../utils/Utils";
import { toast } from "react-toastify";
import { STATUS } from "../../utils/Constants";
import CustomDialog from "components/CustomDialog";

const columns = [
  { id: "company", label: "Company", minWidth: 170 },
  { id: "product", label: "Product", minWidth: 100 },
  {
    id: "volume",
    label: "Volume",
    minWidth: 170,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "date",
    label: "Date",
    minWidth: 170,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
];

function createData(company, product, volume, date) {
  return { company, product, volume, date };
}

const rows = [
  createData("India", "IN", 1324171354, 3287263),
  createData("China", "CN", 1403500365, 9596961),
  createData("Italy", "IT", 60483973, 301340),
  createData("United States", "US", 327167434, 9833520),
  createData("Canada", "CA", 37602103, 9984670),
  createData("Australia", "AU", 25475400, 7692024),
  createData("Germany", "DE", 83019200, 357578),
  createData("Ireland", "IE", 4857000, 70273),
  createData("Mexico", "MX", 126577691, 1972550),
  createData("Japan", "JP", 126317000, 377973),
  createData("France", "FR", 67022000, 640679),
  createData("United Kingdom", "GB", 67545757, 242495),
  createData("Russia", "RU", 146793744, 17098246),
  createData("Nigeria", "NG", 200962417, 923768),
  createData("Brazil", "BR", 210147125, 8515767),
];

const ViewProduct = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { itemId } = location.state || {};

  const [openModal, setOpenModal] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleCloseDialog = () => {
    setOpenConfirmationDialog(false);
  };

  const { data, isLoading } = productApi.useGetProductQuery({
    queryKeyValue: itemId,
  });

  const [deleteProduct, { isLoading: deleteIsLoading }] =
    productApi.useDeleteProductMutation();
  const [updateProduct, { isLoading: updateIsLoading }] =
    productApi.useUpdateProductMutation();

  const updateProductStatus = async (e) => {
    let { depot, id, createdBy, createdAt, updatedBy, updatedAt, ...result } =
      data?.data;

    if (e.target.checked) {
      const payload = { ...result, status: STATUS.AVAILABLE };
      await updateProduct({
        data: payload,
        id: data?.data?.id,
      }).unwrap();
      toast.success("Product status changed successfully!");
    } else {
      const payload = { ...result, status: STATUS.UNAVAILABLE };
      await updateProduct({
        data: payload,
        id: data?.data?.id,
      }).unwrap();
      toast.success("Product status changed successfully!");
    }
  };

  const handleDeleteProduct = async () => {
    await deleteProduct(data?.data?.id).unwrap();
    toast.success("Product deleted successfully!");
    navigate(-1);
  };

  return (
    <>
      <div className="flex flex-col space-y-4 w-full">
        <Card className="relative flex flex-col flex-wrap justify-center items-center w-full p-4 space-y-4 mt-10">
          <strong className="capitalize">{data?.data?.name}</strong>
          <button
            onClick={() => setOpenModal(true)}
            className="absolute right-2 top-1 flex items-center justify-center bg-gray-300 rounded p-1 w-[80px]"
          >
            Edit <Edit sx={{ fontSize: 16, marginLeft: 1, color: "green" }} />
          </button>
          <div className="flex justify-center space-x-3 w-full">
            <Button variant="contained" sx={{ minWidth: 130 }}>
              Availability
              <Switch
                sx={{
                  backgroundColor: "#D0D0D0",
                  borderRadius: 50,
                  opacity: 0.3,
                  marginLeft: 1,
                }}
                size="small"
                onChange={updateProductStatus}
                checked={data?.data?.status === "available" ? true : false}
              />
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#E75C5C",
                borderColor: "transparent",
                minWidth: 150,
              }}
              onClick={setOpenConfirmationDialog}
            >
              Delete
            </Button>
          </div>
          <div className="flex lg:flex-row flex-col justify-center w-full">
            <div className="flex flex-col space-y-3 items-center lg:w-1/3">
              <div
                className="flex justify-center items-center w-12 h-12 p-2 rounded-full"
                style={{ backgroundColor: COLORS.gray }}
              >
                <CalendarToday fontSize="inherit" />
              </div>
              <sub>Date Created</sub>
              <strong>{FormatDateTime(data?.data?.createdAt || "")}</strong>
            </div>
            <div className="flex flex-col space-y-3 items-center lg:w-1/3">
              <div
                className="flex justify-center items-center w-12 h-12 p-2 rounded-full"
                style={{ backgroundColor: COLORS.gray }}
              >
                <OilBarrel fontSize="inherit" />
              </div>
              <sub>Quantity Available</sub>
              <strong>{FormatNumber(data?.data?.quantity || "")}</strong>
            </div>
            <div className="flex flex-col space-y-3 items-center lg:w-1/3">
              <div
                className="flex justify-center items-center w-12 h-12 p-2 rounded-full"
                style={{ backgroundColor: COLORS.gray }}
              >
                <Sell fontSize="inherit" />
              </div>
              <sub>Unit Price</sub>
              <strong>{FormatNumber(data?.data?.price || "")}</strong>
            </div>
          </div>
        </Card>
        <Card variant="contained" className="h-full p-5 shadow-lg ">
          <strong>Completed Sales Record</strong>
          <CustomTable arrayOfColumns={columns} data={rows} maxHeight={420} />
        </Card>
      </div>

      <EditProduct
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        data={data?.data}
      />

      <CustomDialog
        handleOpenDialog={openConfirmationDialog}
        handleCloseDialog={handleCloseDialog}
        title="Delete Product?"
        body={`Are you sure you want to delete ${data?.data?.name}?`}
        ok={handleDeleteProduct}
        cancel={() => setOpenConfirmationDialog(false)}
        // data={data?.data}
      />
      {/* Occationally used components */}
      {isLoading || deleteIsLoading || updateIsLoading ? <Spinner /> : null}
    </>
  );
};

export default ViewProduct;
