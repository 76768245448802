// import "@fontsource/poppins";
import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { theme } from "./utils/Theme";
import AppRouter from "AppRouter";

// import DetectNetworkConnectivity from "./components/DetectNetworkConnectivity";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

const contextClass = {
  success: "bg-black/50",
  error: "bg-red-600/50",
  info: "bg-gray-600/50",
  warning: "bg-orange-400/50",
  default: "bg-blue-800/50",
  dark: "bg-white-600 font-gray-300"
};

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppRouter />
      </ThemeProvider>

      {/* <DetectNetworkConnectivity /> */}

      <ToastContainer
        toastClassName={({ type }) =>
          contextClass[type || "default"] +
          " relative flex p-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer"
        }
        bodyClassName={() => "flex text-sm font-white font-bold p-3"}
        position="top-center"
        autoClose={3000}
      />
    </>
  );
}

export default App;
