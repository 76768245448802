import { createTheme } from "@mui/material";
import { COLORS, primaryColor } from "./Colors";
import { grey } from "@mui/material/colors";

export const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#002E66",
    },
    // background: {
    //   default: "#040114",
    //   paper: "#040114",
    // },
    text: {
      primary: "#000",
      secondary: grey[500],
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "text" },
          style: {
            textTransform: "none",
            borderRadius: 50,
            // color: "#fff",
          },
        },
        {
          props: { variant: "contained" },
          style: {
            textTransform: "none",
            border: `1px solid ${primaryColor}`,
            backgroundColor: primaryColor,
            borderRadius: 50,
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            textTransform: "none",
            border: `1px solid ${primaryColor}`,
            borderRadius: 50,
            // color: "white",
          },
        },
      ],
    },
    MuiCard: {
      variants: [
        {
          props: { variant: "contained" },
          style: {
            textTransform: "none",
            borderWidth: 0,
            backgroundColor: COLORS.white,
            borderRadius: 15,
            // padding:10,
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            textTransform: "none",
            border: `1px solid ${COLORS.primaryAlpha}`,
            backgroundColor: COLORS.primaryAlpha,
            borderRadius: 15,
            padding: 15,
            // color: "white",
          },
        },
        {
          props: {},
          style: {
            textTransform: "none",
            // border: `1px solid ${primaryColor}`,
            borderRadius: 15,
            //   padding:10,
            // color: "white",
          },
        },
      ],
    },
    MuiTextField: {
      variants: [
        {
          props: { variant: "filled" },
          style: {
            width: "90%",
            marginLeft: "auto",
            marginRight: "auto",
            paddingBottom: 0,
            marginTop: 0,
            fontWeight: 500,
            borderRadius: 50,
          },
        },
      ],
    },
    MuiAccordion: {
      variants: [
        {
          props: {},
          style: {
            // backgroundColor: "transparent",
          },
        },
      ],
    },
    MuiAccordionSummary: {
      variants: [
        {
          props: {},
          style: {
            backgroundColor: "transparent",
          },
        },
      ],
    },
    MuiAccordionDetails: {
      variants: [
        {
          props: {},
          style: {
            backgroundColor: "transparent",
          },
        },
      ],
    },
    MuiAccordionActions: {
      variants: [
        {
          props: {},
          style: {
            width: "90%",
            marginLeft: "auto",
            marginRight: "auto",
            paddingBottom: 0,
            marginTop: 0,
            fontWeight: 500,
            backgroundColor: "white",
          },
        },
      ],
    },
    MuiBottomNavigation: {
      variants: [
        {
          props: {},
          style: {
            width: "100%",
            color: "white",
            backgroundColor: COLORS.primaryLight,

          },
        },
      ],
    },
    MuiBottomNavigationAction: {
      variants: [
        {
          props: {},
          style: {
            color: "white",
          },
        },
      ],
    },
  },
});
