import React, { useEffect } from "react";
import { Button, Icon, Typography } from "@mui/material";
import CustomInput from "../../components/CustomInput";
import { useFormik } from "formik";
import { getTextFieldFormikProps } from "../../utils/Utils";
import Spinner from "../../components/Spinner";
import { AddProductSchema } from "../../yup";
import { BasicModal } from "../../components/Modal";
import { productApi } from "../../apis/services/ProductApi";
import { toast } from "react-toastify";

const EditProduct = ({ openModal, handleCloseModal, data }) => {
  const [updateProduct, { isLoading }] = productApi.useUpdateProductMutation();

  const formik = useFormik({
    initialValues: {
      name: data?.name || "",
      price: data?.price || 0,
      quantity: data?.quantity || 0,
      unitOfMeasurement: data?.unitOfMeasurement || ""
    },
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: AddProductSchema,
    onSubmit: async (values, action) => {
      // console.log(values);
      try {
        const res = await updateProduct({
          id: data.id,
          data: values
        }).unwrap();
        handleCloseModal();
        action.resetForm();
        if (res?.status === "success") {
          toast.success("Product updated successfully!");
        }
      } catch (error) {
        console.log(error?.data?.message);
        toast.error(error?.data?.message);
      }
    }
  });

  useEffect(() => {
    const payload = {
      name: data?.name || formik.values.name,
      price: data?.price || formik.values.price,
      quantity: data?.quantity || formik.values.quantity,
      unitOfMeasurement:
        data?.unitOfMeasurement || formik.values.unitOfMeasurement
    };
    formik.setValues(payload);
  }, [data]);

  return (
    <>
      <BasicModal
        handleOpenModal={openModal}
        handleCloseModal={handleCloseModal}
        cl="absolute lg:w-[40%] w-[80%] m-auto h-fit rounded-xl"
      >
        <Typography
          id="modal-modal-description"
          variant="span"
          component="span"
          className="flex flex-col space-y-6"
        >
          <div className="flex justify-between border-b border-dashed border-black p-2">
            <p>Edit Product</p>
            <button
              type="button"
              onClick={handleCloseModal}
            >
              <Icon>highlight_off</Icon>
            </button>
          </div>

          <div className="flex lg:flex-row flex-col justify-center">
            <form
              onSubmit={formik.handleSubmit}
              className="flex flex-col flex-wrap items-center justify-center lg:w-[70%] w-full p-4 space-y-5"
            >
              <CustomInput
                label="Product Name"
                type={"text"}
                {...getTextFieldFormikProps(formik, "name")}
              />
              <CustomInput
                label="Quantity"
                type={"number"}
                {...getTextFieldFormikProps(formik, "quantity")}
              />
              <CustomInput
                label="Unit Price"
                type={"number"}
                {...getTextFieldFormikProps(formik, "price")}
              />
              <CustomInput
                label="Unit of Measurement"
                type={"text"}
                {...getTextFieldFormikProps(formik, "unitOfMeasurement")}
              />
              <Button variant="contained" type="submit" className="w-full">
                Update
              </Button>
            </form>
          </div>

          {/* Occationally used components */}
          {formik.isSubmitting || isLoading ? <Spinner /> : null}
        </Typography>
      </BasicModal>
    </>
  );
};

export default EditProduct;
