import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Icon, Typography } from "@mui/material";

// import QuestionMark from "../assets/images/QuestionMark.gif";
import { COLORS } from "../utils/Colors";

// export function BasicModal({ handleOpen, handleClose, children, cl }) {
export const BasicModal = ({
  handleOpenModal,
  handleCloseModal,
  children,
  cl,
  onClose,
  bottom
}) => {
  return (
    <>
      <Modal
        open={handleOpenModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={cl}
      >
        <Box className="outline-none">
          <div
            className="p-6 rounded-3xl outline-none"
            style={{ backgroundColor: COLORS.background }}
          >
            {children}
          </div>

          {bottom}
        </Box>
      </Modal>
    </>
  );
};

export const ConfirmationModal = ({
  handleOpenModal,
  handleCloseModal,
  children,
  cl,
  icon
}) => {
  return (
    <>
      <Modal
        open={handleOpenModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={cl}
      >
        <Box className="light-gray-bg p-4 rounded-2xl">
          <Typography>
            <div className="mb-2 flex justify-end w-full">
              <button
                type="button"
                onClick={handleCloseModal}
                className="border border-white rounded-full p-1 h-min text-xs"
              >
                <Icon className="text-white">close</Icon>
              </button>
            </div>
            <div className="flex justify-center">
              {/* <img src={QuestionMark} alt="" width={70} /> */}
            </div>
          </Typography>
          <Typography>
            <div className="px-5">{children}</div>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};
