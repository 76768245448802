import React, { useEffect } from "react";
import { BasicModal } from "../../components/Modal";
import { Button, Icon, Typography } from "@mui/material";
import { CalendarMonth } from "@mui/icons-material";
import CustomInput from "../../components/CustomInput";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { getTextFieldFormikProps } from "../../utils/Utils";
import DateTimePicker from "react-datetime-picker";
import { invoiceApi } from "../../apis/services/InvoiceApi";
import Spinner from "../../components/Spinner";
import { format } from "date-fns";
import { orderApi } from "apis/services/OrderApi";

const CreateInvoice = ({ openModal, handleCloseModal, data }) => {
  const [createInvoice, { isLoading }] = invoiceApi.useCreateInvoiceMutation();

  const [, { isLoading: updateIsLoading }] = orderApi.useUpdateOrderMutation();

  const formik = useFormik({
    initialValues: {
      orderId: data?.id,
      invoiceNote: "",
      dueDate: format(new Date(), "yyyy-MM-dd")
    },
    validateOnChange: true,
    validateOnBlur: true,
    // validationSchema: Yup.object().shape({
    //   availableVolume: Yup.string().required("Available Volume is required!"),
    // }),
    onSubmit: async (values, action) => {
      console.log({ ...values });
      //   console.log(format(values.dueDate, "yyyy-MM-dd"));
      try {
        const res = await createInvoice(values).unwrap();
        console.log(res);
        // await updateOrder({
        //   orderId: values.orderId,
        //   status: STATUS.APPROVED,
        // }).unwrap();
        action.resetForm();
        toast.success("Invoice created successfully!");
      } catch (error) {
        console.log(error);
        toast.error(error?.data?.message);
      }
    }
  });

  useEffect(() => {
    formik.setFieldValue("orderId", data?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.id]);

  return (
    <>
      <BasicModal
        handleOpenModal={openModal}
        handleCloseModal={handleCloseModal}
        cl="absolute lg:w-[35%] w-[80%] m-auto h-fit rounded-xl"
      >
        <Typography
          id="modal-modal-description"
          variant="span"
          component="span"
          className="flex flex-col space-y-6"
        >
          <>
            <div className="flex justify-between border-b border-black border-dashed p-2">
              <p>Create Invocie</p>
              <button type="button" onClick={handleCloseModal}>
                <Icon>highlight_off</Icon>
              </button>
            </div>
            <div className="relative flex flex-col w-full items-center h-full space-y-8">
              <div className="w-full">
                <label>Invoice note</label>
                <CustomInput
                  placeholder="Note"
                  {...getTextFieldFormikProps(formik, `invoiceNote`)}
                />
              </div>

              <div className="w-full">
                <label>Due Date</label>
                <DateTimePicker
                  onChange={(date) =>
                    formik.setFieldValue("dueDate", format(date, "yyyy-MM-dd"))
                  }
                  value={formik.values.dueDate}
                  disableClock={true}
                  format="y-MM-dd"
                  required={true}
                  calendarIcon={<CalendarMonth />}
                  clearIcon={null}
                  minDate={new Date()}
                  shouldCloseWidgets={({ reason, widget }) =>
                    reason !== "outsideAction" && widget === "calendar"
                  }
                  calendarClassName="rounded-xl"
                  className="bg-transparent rounded-lg min-w-full w-full p-3 border border-black/30 hover:none"
                />
              </div>

              <Button variant="contained" onClick={formik.handleSubmit}>
                Create
              </Button>
            </div>
            {/* {content} */}
          </>
        </Typography>
        {/* Occationally used components */}
        {isLoading || updateIsLoading ? <Spinner /> : null}
      </BasicModal>
    </>
  );
};

export default CreateInvoice;
