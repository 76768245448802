import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { COLORS } from "utils/Colors";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function CustomDialog({
  handleOpenDialog,
  handleCloseDialog,
  ...props
}) {
  // const [open, setOpen] = React.useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  return (
    <>
      <Dialog
        open={handleOpenDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {props.body}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={props.ok}
            variant="contained"
            sx={{ backgroundColor: COLORS.red, borderColor: 'transparent', color: "white" }}
          >
            Delete
          </Button>
          <Button onClick={props.cancel}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
