import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Pagination } from "@mui/material";
import { COLORS } from "../utils/Colors";
import { NoDataFound } from "./NoDataFound";

export default function CustomTable({
  arrayOfColumns,
  data,
  maxHeight,
  viewAction,
  totalPages,
  totalData,
  handleChangePage,
}) {
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // const handleChangePage = (event, newPage) => {
  //   // setPage(newPage);
  //   console.log(newPage);
  //   const pg = newPage + 1;
  //   try {
  //     setSearchPhrase(`&page=${pg}`);
  //     setPage(newPage);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };

  const columns = arrayOfColumns;

  const rows = data;

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: maxHeight }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead
            sx={{
              "& th": {
                color: "gray",
                fontWeight: "Bold",
                fontSize: "14px",
                backgroundColor: COLORS.gray,
              },
            }}
          >
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              sx={
                rows?.props?.children?.length > 0 ? { display: "none" } : null
              }
            >
              <TableCell colSpan={6}>
                <NoDataFound height="100px" width="100px" />
              </TableCell>
            </TableRow>
            {rows?.props?.children?.map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        onClick={() => viewAction(row.id)}
                        className="cursor-pointer"
                      >
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="flex flex-row justify-center py-4">
        <Pagination
          count={totalPages}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
      </div>
    </Paper>
  );
}
