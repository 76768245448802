// AppRouter.js
import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import Spinner from "components/Spinner";
import { RequireAuth } from "apis/services/RequireAuth";
import ViewOrder from "pages/orders/ViewOrder";
import ViewProduct from "pages/products/ViewProduct";
import ViewInvoice from "pages/invoice/ViewInvoice";
import EditDepot from "pages/settings/EditDepot";

const SignIn = lazy(() => import("./pages/auth/SignIn"));
const ForgotPassword = lazy(() => import("./pages/auth/ForgotPassword"));
const Layout = lazy(() => import("./pages/layout/Layout"));
const Dashboard = lazy(() => import("./pages/dashboard/Dashboard"));
const Orders = lazy(() => import("./pages/orders/Orders"));
const Products = lazy(() => import("./pages/products/Products"));
const Wallet = lazy(() => import("./pages/wallet/Wallet"));
const SettingsLayout = lazy(() => import("./pages/settings/SettingsLayout"));
const Profile = lazy(() => import("./pages/settings/Profile"));
const Settings = lazy(() => import("./pages/settings/Settings"));
const ProductLayout = lazy(() => import("./pages/products/ProductLayout"));
// const ViewProduct = lazy(() => import("./pages/products/ViewProduct"));
// const ViewOrder = lazy(() => import("./pages/orders/ViewOrder"));
const OrdersLayout = lazy(() => import("./pages/orders/OrdersLayout"));
const InvoiceLayout = lazy(() => import("./pages/invoice/InvoiceLayout"));
const Invoice = lazy(() => import("./pages/invoice/Invoice"));
// const ViewInvoice = lazy(() => import("./pages/invoice/ViewInvoice"));
const NoMatch = lazy(() => import("./pages/NoMatch"));

const AppRouter = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        <Route exact index element={<SignIn />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />

        <Route element={<RequireAuth />}>
          <Route exact path="" element={<Layout />}>
            <Route element={<RequireAuth />}>
              <Route exact path="dashboard" element={<Dashboard />} />
            </Route>
            <Route exact path="settings" element={<SettingsLayout />}>
              <Route exact index element={<Settings />} />
              <Route exact path="profile" element={<Profile />} />
              <Route exact path="edit-depot" element={<EditDepot />} />
            </Route>
            <Route exact path="products" element={<ProductLayout />}>
              <Route exact index element={<Products />} />
              <Route exact path="view-product" element={<ViewProduct />} />
            </Route>

            <Route exact path="orders" element={<OrdersLayout />}>
              <Route exact index element={<Orders />} />
              <Route exact path="view-order" element={<ViewOrder />} />
              <Route exact path="view-invoice" element={<ViewInvoice />} />
            </Route>

            <Route exact path="invoice" element={<InvoiceLayout />}>
              <Route exact index element={<Invoice />} />
              <Route exact path="view-invoice" element={<ViewInvoice />} />
            </Route>

            <Route exact path="wallet" element={<Wallet />} />
          </Route>
        </Route>
        <Route exact path="*" element={<NoMatch />} />
      </Routes>
    </Suspense>
  );
};

export default AppRouter;
