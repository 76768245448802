import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { orderApi } from "../../apis/services/OrderApi";
import { Avatar, Button, Card } from "@mui/material";
import { COLORS } from "../../utils/Colors";
import Spinner from "../../components/Spinner";
import { CustomInputOutline } from "../../components/CustomInput";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import {
  FormatCurrency,
  FormatDateTime,
  getTextFieldFormikProps
} from "../../utils/Utils";
import CreateInvoice from "../invoice/CreateInvoice";
import { DEPOT_ORDER_STATUS } from "utils/Constants";

const tableHead = [
  "Name",
  "Requested Quantity",
  "Available Quantity",
  "Price",
  "Allocate Quantity"
];

const scheduleTableHead = ["Name", "Quantity", "Price"];

const ViewOrder = () => {
  const location = useLocation();
  const { itemId } = location.state || {};

  const [openModal, setOpenModal] = useState(false);
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const { data, isLoading } = orderApi.useGetOrdersQuery({
    queryKeyValue: itemId
  });

  const [allocateVolume, { isLoading: allocateVolumeIsLoading }] =
    orderApi.useAllocateVolumeMutation();

  const formik = useFormik({
    initialValues: {},
    validateOnChange: true,
    validateOnBlur: true,
    // validationSchema: Yup.object().shape({
    //   availableVolume: Yup.string().required("Available Volume is required!"),
    // }),
    onSubmit: async (values, action) => {
      //   console.log({ ...values });
      try {
        const { product, ...rest } = values;
        const res = Object.keys(rest);
        const payload = {
          product: values.product,
          availableVolume: values[res[0]]
        };
        const result = await allocateVolume({
          id: data?.data?.id,
          data: payload
        }).unwrap();
        console.log(result);
        action.resetForm();
        toast.success("Available quantity allocated!");
      } catch (error) {
        // console.log(error);
        toast.error(error);
      }
    }
  });

  const onSubmit = (item) => {
    formik.setFieldValue("product", item?.product?.id);
    formik.handleSubmit();
  };

  return (
    <>
      <Card className="flex flex-col items-center p-4 w-full space-y-4">
        <Avatar
          className="h-52 w-52"
          sx={{
            width: 120,
            height: 120,
            border: 1,
            borderColor: COLORS.primary
          }}
          src={data?.data?.depot?.logo?.url}
        />
        <strong className="text-xl">{data?.data?.stationHq?.name}</strong>
        <div className="flex flex-col w-full text-left">
          <p>{`Order Code: ${data?.data?.orderCode || ""}`}</p>
          <p
            className={
              data?.data?.status === DEPOT_ORDER_STATUS.SCHEDULED
                ? "visible"
                : "hidden"
            }
          >{`Pick Up Date: ${
            FormatDateTime(data?.data?.schedule?.pickupDate) || ""
          }`}</p>
          <p
            className={
              data?.data?.status === DEPOT_ORDER_STATUS.SCHEDULED
                ? "visible"
                : "hidden"
            }
          >{`Note: ${data?.data?.schedule?.scheduleNote || ""}`}</p>
        </div>
        <span className="px-4 py-1 rounded-full text-orange-500 bg-orange-500/30">
          {data?.data?.status}
        </span>

        {data?.data?.status === DEPOT_ORDER_STATUS.SCHEDULED ? (
          <div className={"overflow-x-auto w-full"}>
            <table className="mt-10 w-full">
              <tr className="bg-blue-900/50 p-4">
                {scheduleTableHead?.map((item, index) => {
                  return (
                    <th className="p-4 border-b border-collapse border-black">
                      {item}
                    </th>
                  );
                })}
              </tr>
              {data?.data?.details?.map((item, index) => {
                return (
                  <tr>
                    <td className="p-4 border-b border-r border-collapse border-black">
                      {item?.product?.name}
                    </td>
                    {/* <td className="p-4 border-b border-r border-collapse border-black">
                      {item?.requestedVolume}
                    </td> */}
                    <td className="p-4 border-b border-r border-collapse border-black">
                      {item?.availableVolume}
                    </td>
                    <td className="p-4 border-b border-r border-collapse border-black">
                      {FormatCurrency(item?.amount)}
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        ) : (
          <div className={"overflow-x-auto w-full"}>
            <table className="mt-10 w-full">
              <tr className="bg-blue-900/50 p-4">
                {tableHead?.map((item, index) => {
                  return (
                    <th className="p-4 border-b border-collapse border-black">
                      {item}
                    </th>
                  );
                })}
              </tr>
              {data?.data?.details?.map((item, index) => {
                return (
                  <tr>
                    <td className="p-4 border-b border-r border-collapse border-black">
                      {item?.product?.name}
                    </td>
                    <td className="p-4 border-b border-r border-collapse border-black">
                      {item?.requestedVolume}
                    </td>
                    <td className="p-4 border-b border-r border-collapse border-black">
                      {item?.availableVolume}
                    </td>
                    <td className="p-4 border-b border-r border-collapse border-black">
                      {item?.amount}
                    </td>
                    <td className="p-4 border-b border-collapse border-black min-w-[200px]">
                      {/* {item?.unitOfMeasurement} */}
                      <CustomInputOutline
                        type="number"
                        placeholder="Available Volume"
                        name={`availableVolume${index}`}
                        suffix={
                          <Button
                            type="button"
                            onClick={() => onSubmit(item)}
                            variant="contained"
                            className="lg:min-w-20 "
                          >
                            Allocate
                          </Button>
                        }
                        {...getTextFieldFormikProps(
                          formik,
                          `availableVolume${index}`
                        )}
                      />
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        )}

        <strong className="text-lg text-right w-full pr-4">{`Total Amount: ${FormatCurrency(
          data?.data?.totalAmount || 0
        )}`}</strong>

        {data?.data?.status === DEPOT_ORDER_STATUS.SCHEDULED ? null : (
          <Button variant="contained" onClick={() => setOpenModal(true)}>
            Create Invoice
          </Button>
        )}
      </Card>

      <CreateInvoice
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        data={data?.data}
      />
      {/* Occationally used components */}
      {isLoading || allocateVolumeIsLoading ? <Spinner /> : null}
    </>
  );
};

export default ViewOrder;
