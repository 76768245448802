// const breadcrumbNameMap = {
//   "/dashboard": "Dashboard",
//   "/products": "Products",
//   "/products/view-products": "View Product",
//   "/orders": "Orders",
//   "/orders/view-order": "View Order",
//   "/wallet": "Wallet",
//   "/settings": "Settings",
//   "/settings/profile": "Profile",
// };

export const APP_ROUTES_NAME = {
  Dashboard: "Dashboard",
  Products: "Products",
  View_Product: "View Product",
  Orders: "Orders",
  View_Orders: "View Orders",
  Invoice: "Invoice",
  View_Invoice: "View Invoice",
  Wallet: "Wallet",
  Transactions: "Transactions",
  Settings: "Settings",
  Profile: "Profile"
};

export const APP_ROUTES_PATH = {
  Dashboard: "/dashboard",
  Products: "/products",
  View_Product: "/products/view-products",
  Orders: "/orders",
  View_Orders: "/orders/view-order",
  Invoice: "/invoice",
  View_Invoice: "/orders/view-invoice",
  Wallet: "/wallet",
  Settings: "/settings",
  Profile: "/settings/profile"
};

export const STATUS = {
  ALL: "all",
  APPROVED: "approved",
  ACCEPTED: "accepted",
  QUEUED: "queued",
  CANCELLED: "cancelled",
  PENDING: "pending",
  DECLINED: "declined",
  AVAILABLE: "available",
  UNAVAILABLE: "unavailable",
  SCHEDULED: "scheduled",
  COMPLETED: "completed",
  PAID: "paid"
};

export const EMPTY_ARRAY = [{}, {}, {}, {}, {}, {}];

export const TRANSACTION_STATUS = {
  PENDING: "pending",
  SUCCESSFUL: "successful",
  FAILED: "failed"
};

export const TRANSACTION_STATUS_ARRAY = ["pending", "successful", "failed"];

export const TRANSACTION_TYPES = {
  DEPOSIT: "deposit",
  TRANSFER: "transfer",
  REFUND: "refund",
  DISPUTE: "dispute",
  WITHDRAWAL: "withdrawal"
};
export const DEPOT_ORDER_STATUS = {
  PENDING: "pending",
  CANCELLED: "cancelled",
  SCHEDULED: "scheduled",
  INVOICED: "invoiced",
  COMPLETED: "completed"
};

export const DEPOT_ORDER_STATUS_ARRAY = [
  "pending",
  "cancelled",
  "scheduled",
  "invoiced",
  "completed"
];

export const TRANSACTION_TYPES_ARRAY = [
  "deposit",
  "transfer",
  "refund",
  "dispute",
  "withdrawal"
];
