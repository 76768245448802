import React from "react";
import { Avatar, Button, Card } from "@mui/material";
import { useFormik } from "formik";
import { getTextFieldFormikProps } from "../../utils/Utils";
import Spinner from "../../components/Spinner";
import CustomInput from "../../components/CustomInput";
import * as Yup from "yup";
import useAuthUser from "../../hooks/useAuthUser";
import { profileApi } from "../../apis/services/Profile";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { DropzoneUploader } from "components/Dropzone";

const EditDepot = () => {
  const { user } = useAuthUser();
  const navigate = useNavigate();
  const location = useLocation();
  const { depot } = location.state || {};

  //   console.log("Profile::::", depot);

  const [updateDepot, { isLoading }] = profileApi.useUpdateDepotMutation();

  const formik = useFormik({
    initialValues: {
      logo: depot?.logo?.url || "",
      name: depot?.name || "",
      phoneNumber: depot?.phoneNumber || "",
      email: depot?.email || "",
      address: depot?.address || "",
      state: depot?.state || ""
    },
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: Yup.object().shape({
      logo: Yup.string().required("Logo is required!"),
      name: Yup.string().required("Name is required!"),
      phoneNumber: Yup.number()
        .positive()
        .integer()
        .required("Phone Number is required!"),
      email: Yup.string().required("Email is required!"),
      address: Yup.string().required("Address is required!"),
      state: Yup.string().required("State is required!")
    }),
    onSubmit: async (values, action) => {
      console.log(values);
      try {
        const payload = { depot: { ...values } };
        const res = await updateDepot({
          id: depot.id,
          data: payload
        }).unwrap();
        console.log(res);
        navigate(-1);
        toast.success("Depot details updated successfully!");
      } catch (error) {
        console.log(error.data.message);
        toast.error(error.data.message);
      }
    }
  });

  const handleFileToBase64 = (file) => {
    const selectedFile = file[0];
    const reader = new FileReader();
    reader.onload = () => {
      const base64Data = reader.result.split(",")[1];
      formik.setFieldValue("logo", `data:image/jpeg;base64,` + base64Data);
    };
    reader.readAsDataURL(selectedFile);
  };

  return (
    <>
      <Card className="flex flex-col flex-wrap justify-center items-center space-y-4 p-4 w-full">
        <form
          onSubmit={formik.handleSubmit}
          className="flex-1 flex flex-col items-center justify-center lg:w-[50%] w-full p-4 space-y-5"
        >
          {/* <Avatar
            sx={{ width: 100, height: 100 }}
            src={user?.depot?.logo?.url}
          /> */}

          <DropzoneUploader
            selectedFile={(file) => handleFileToBase64(file)}
            currentLogo={user?.depot?.logo?.url}
          />

          <CustomInput
            label="Name"
            type="text"
            {...getTextFieldFormikProps(formik, "name")}
          />
          <CustomInput
            label="Phone Number"
            type="text"
            disabled
            {...getTextFieldFormikProps(formik, "phoneNumber")}
          />
          <CustomInput
            label="Email"
            type="text"
            disabled
            {...getTextFieldFormikProps(formik, "email")}
          />
          <CustomInput
            label="Address"
            type="text"
            {...getTextFieldFormikProps(formik, "address")}
          />
          <CustomInput
            label="State"
            type="text"
            {...getTextFieldFormikProps(formik, "state")}
          />
          <Button variant="contained" type="submit" className="w-full">
            Update Depot
          </Button>
        </form>
      </Card>

      {/* Occationally used components */}
      {formik.isSubmitting || isLoading ? <Spinner /> : null}
    </>
  );
};

export default EditDepot;
